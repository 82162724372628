module.exports = {
    INVALID_LANGUAGE: 1000,
    EMPTY_PAYLOAD: 1001,
    MISSING_USERNAME: 1002,
    MISSING_PASSWORD: 1003,
    MISSING_FIRSTNAME: 1004,
    MISSING_LASTNAME: 1005,
    MISSING_EMAIL: 1006,
    INVALID_USERNAME: 2001,
    INVALID_PASSWORD: 2002,
    INVALID_CREDENTIALS: 2003,
    NEW_OLD_PASSWORD_DIFFERENT: 2004,
    USER_ALREADY_EXISTS: 2005,
    EMAIL_NOT_VERIFIED: 2006,
    EMAIL_ALREADY_EXISTS: 2007,
    SAME_PASSWORD_GIVEN: 2008,
    DATABASE_ERROR: 5000

    // Add more custom error codes as needed
}